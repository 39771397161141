import React from 'react';
import { Play, Clock } from 'react-feather';
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom";
import { Alert, FormGroup, Input, Button } from 'reactstrap';
import { AuditReportsListActions, AuditReportStatusBadge, AuditReportsListSearch, AuditReportPdf, SharpAuditReportPdf, GroupedReportPdf } from '.';
import moment from 'moment';
import * as Icon from "react-feather";
import { useMediaQuery } from 'react-responsive';
import { groupBy } from 'lodash';

export const AuditReportsList = (props) => {
    const isSmallDevice = useMediaQuery({ maxWidth: 768 });

    // Define columns
    const columns = isSmallDevice ? [
        {
            name: 'Reference',
            selector: row => row.reference,
            sortable: false,
            ignoreRowClick: true,
            conditionalCellStyles: [
                {
                    when: row => row._isGroupHeader,
                    style: {
                        backgroundColor: 'black',
                        color: 'white',
                    },
                },
            ],
            cell: row => {
                if (!row._isGroupHeader) {
                    const basePath = row.type === 'Sharps' ? '/audit-reports/sharps/' : '/audit-reports/';
                    return row.status === 'Closed' || row.status === 'Open'
                        ? <span>{row.reference}</span>
                        : <NavLink to={basePath + row.id}>{row.reference}</NavLink>;
                }
                else {
                    return <span>{row.zoneName}</span>
                }
            }
        },
        {
            button: true,
            conditionalCellStyles: [
                {
                    when: row => row._isGroupHeader,
                    style: {
                        backgroundColor: 'black',
                        color: 'white',
                    },
                },
            ],
            cell: row => {
                if (!row._isGroupHeader) {
                    switch (row.status) {
                        case 'Open':
                            return <Button onClick={() => props.handleStart(row)} size="sm" color="success"><Play className="feather" /></Button>;
                        case 'Completed':
                            return row.type === 'Sharps'
                                ? <SharpAuditReportPdf reportId={row.id}/> : <AuditReportPdf reportId={row.id}/>;
                        default:
                            return null;
                    } 
                }                
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            conditionalCellStyles: [
                {
                    when: row => row._isGroupHeader,
                    style: {
                        backgroundColor: 'black',
                        color: 'white',
                    },
                },
            ],
            cell: row => {
                if (!row._isGroupHeader) {
                    return (
                        <AuditReportsListActions
                            report={row}
                            handleEdit={props.handleEdit}
                            handleDelete={props.handleDelete}
                        />
                    );
                }
            }
        }
    ] : [
        {
            name: 'Reference',
            selector: row => row.reference,
            sortable: true,
            ignoreRowClick: true,
            cell: row => {
                const basePath = row.type === 'Sharps' ? '/audit-reports/sharps/' : '/audit-reports/';
                return row.status === 'Closed' || row.status === 'Open'
                    ? <span>{row.reference}</span>
                    : <NavLink to={basePath + row.id}>{row.reference}</NavLink>;
            }
        },
        {
            name: 'Zone',
            selector: row => row.zoneName,
            sortable: true,
            ignoreRowClick: true,
            cell: row => <span>{row.zoneName}</span>
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            ignoreRowClick: true,
            cell: row => <AuditReportStatusBadge status={row.status}/>
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            ignoreRowClick: true,
            cell: row => <span>{row.type}</span>
        },
        {
            button: true,
            cell: row => {
                switch (row.status) {
                    case 'Open':
                        return <Button onClick={() => props.handleStart(row)} size="sm" color="success"><Play className="feather" /></Button>;
                    case 'Completed':
                        return row.type === 'Sharps'
                            ? <SharpAuditReportPdf reportId={row.id}/> : <AuditReportPdf reportId={row.id}/>;
                    default:
                        return null;
                }
            }
        },
        {
            name: 'Open On',
            selector: 'openOnUtc',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                if (row.openOnUtc) {
                    const formattedDate = moment(row.openOnUtc).format('DD/MM/YYYY');
                    if (row.isOverdue) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>{formattedDate}</span>
                                <Icon.Clock className="feather" color="red" style={{ marginLeft: '8px' }} />
                            </div>
                        );
                    } else {
                        return <span>{formattedDate}</span>;
                    }
                } else {
                    return null;
                }
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <AuditReportsListActions
                        report={row}
                        handleEdit={props.handleEdit}
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ];

    // Function to create grouped data
    const groupItemsByZone = (items) => {
        // Group items by 'zoneName' property
        const grouped = groupBy(items, 'zoneName');

        // Create output with group headers and items
        return Object.keys(grouped).flatMap(zoneName => [
            { zoneName, _isGroupHeader: true }, // Group header
            ...grouped[zoneName]
        ]);
    };


    // Transform data for mobile view
    const formattedData = isSmallDevice ? groupItemsByZone(props.items) : props.items;

    const handleStatusChange = (e) => {
        props.handleStatusChange(e);
    };

    const handleTypeChange = (e) => {
        props.handleTypeChange(e);
    }

    const handleZoneSelect = (e) => {
        props.handleZoneChange(e);
    }

    const handleStartDateChange = (e) => {
        props.handleStartDateChange(e);
    }

    const handleEndDateChange = (e) => {
        props.handleEndDateChange(e);
    }

    const handleGroupReport = (e) => {
        props.handleGroupReport(e);
    }

    const hasAny = props.items && props.items.length > 0;

    const containerStyle = {
        display: 'flex',
        justifyContent: isSmallDevice ? 'flex-start' : 'flex-start',
        marginBottom: isSmallDevice ? '0' : '10px',
        flexWrap: 'wrap',
        flexDirection: isSmallDevice ? 'column' : 'row',
        gap: isSmallDevice ? '0' : '10px'
    };

    const formGroupStyle = {
        flex: isSmallDevice ? '1 1 100%' : '1 1 auto',
        marginBottom: '10px'
    };

    const searchStyle = {
        flex: isSmallDevice ? '1 1 100%' : '1 1 auto'
    };

    const buttonStyle = {
        flex: '1 1 100%',
        marginTop: isSmallDevice ? '10px' : '0'
    };
    

    return (
        <>
            <div style={containerStyle}>
                <FormGroup style={formGroupStyle}>
                    <Input type="select" name="statusSelect" id="statusSelect" value={props.status}
                           onChange={handleStatusChange}>
                        <option value="">Any Status</option>
                        <option value="Open">Open</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                        <option value="Closed">Closed</option>
                    </Input>
                </FormGroup>

                <FormGroup style={formGroupStyle}>
                    <Input type="select" name="typeSelect" id="typeSelect" value={props.type} onChange={handleTypeChange}>
                        <option value="">Any Type</option>
                        <option value="Audit">Audits</option>
                        <option value="Sharps">Sharps</option>
                    </Input>
                </FormGroup>

                <FormGroup style={formGroupStyle}>
                    <Input type="select" name="zoneSelect" id="zoneSelect" value={props.zoneId} onChange={handleZoneSelect}>
                        <option value="">Any Zone</option>
                        {props.zones.map(zone => <option key={zone.id} value={zone.id}>{zone.name}</option>)}
                    </Input>
                </FormGroup>

                <FormGroup style={formGroupStyle}>
                    <Input
                        type="date"
                        name="startDate"
                        id="startDate"
                        placeholder="Start Date"
                        onChange={handleStartDateChange}
                    />
                </FormGroup>

                <FormGroup style={formGroupStyle}>
                    <Input
                        type="date"
                        name="endDate"
                        id="endDate"
                        placeholder="End Date"
                        onChange={handleEndDateChange}
                    />
                </FormGroup>

                <div style={searchStyle}>
                    <AuditReportsListSearch handleSearch={props.handleSearch}/>
                </div>
            </div>

            <div style={buttonStyle}>
                <GroupedReportPdf reports={props.items}/>
            </div>

            {hasAny ? (
                <DataTable
                    columns={columns}
                    data={formattedData}
                    defaultSortField="zoneName"                    
                    pagination
                    highlightOnHover
                    noHeader
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
            ) : (
                <>
                    <br/>
                    <Alert color="warning">No reports were found</Alert>
                </>
            )}
        </>
    );
};
