
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as Icon from 'react-feather';
import font from '../../fonts/HelveticaNeue.ttf';
import fontSB from '../../fonts/HelveticaNeue-Medium.ttf';
import { Page, View, Text, Font, Document, StyleSheet, pdf, Image } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {  Button }  from 'reactstrap';
import {PdfDocument} from "./AuditReportPdf";
import {SharpPdfDocument} from "./SharpAuditReportPdf";
import {SharpService} from "../../services/SharpService";
import Logo from '../../graphics/logo-no-text-transparent.png';

export const GroupedReportPdf = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [zoneSharps, setZoneSharps] = useState({
        sharps: [],
    });
    const sharpService = new SharpService();

    useEffect(() => {

        props.reports.forEach(report => {
            sharpService.getSharps(1, 1000, 'Type', 'asc', null, report.zoneId).then(
                (response) => {
                    const sharps = response.data.map(sharp => ({
                        ...sharp
                    }));
                    setZoneSharps(prevState => ({
                        ...prevState,
                        sharps: sharps,
                    }));
                },
                () => {
                    // Error
                }
            );
            
            setIsLoaded(true);
        });
    }, [props.reports]);
    

    // PDF 
    const fileName = "GroupedReports_" + moment(new Date()).format('DD-MM-YYYY_HH-mmA') + ".pdf";
    const generatePdfDocument = async () => {
        const blob = await pdf((
            <GroupedReportPdfDocument reports={props.reports} zoneSharps={zoneSharps}/>
        )).toBlob();
        saveAs(blob, fileName);
    }

    return (isLoaded ?
            <Button onClick={generatePdfDocument} size="sm" color="success"><Icon.Download className="feather" /> Grouped Report</Button> :
            <Button size="sm" color="success"><Icon.Download className="feather" /> Grouped Report</Button>
    )
}


// Create Document Component
export const GroupedReportPdfDocument = (props) => {
    const dateCreated = moment(new Date()).format('DD/MM/YYYY');
    
    // Register fonts
    Font.register({
        family: 'Helvetica Neue LT Std',
        fonts: [
            { src: font },
            { src: fontSB, fontStyle: 'semibold' },
        ],
    });

    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
        },
        title: {
            fontSize: 30,
            textAlign: 'center',
            fontFamily: 'Segoe UI',
            fontStyle: 'semibold',
            marginBottom: 30,
        },
        tableOfContentsTitle: {
            fontSize: 22,
            textAlign: 'center',
            marginBottom: 20,
            fontFamily: 'Segoe UI',
        },
        tocItem: {
            fontSize: 12,
            marginVertical: 4,
            fontFamily: 'Segoe UI',
        },
        author: {
            fontSize: 16,
            textAlign: 'center',
            marginBottom: 10,
        },
        date: {
            fontSize: 12,
            textAlign: 'center',
            marginBottom: 20,
        },
        subtitle: {
            fontSize: 20,
            margin: 10,
            fontFamily: 'Segoe UI',
            fontStyle: 'semibold',
        },
        text: {
            margin: 10,
            fontSize: 11,
            fontFamily: 'Segoe UI'
        },
        header: {
            fontSize: 14,
            marginBottom: 20,
            textAlign: 'center',
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 11,
            bottom: 30,
            left: 50,
            right: 0,
            textAlign: 'left',
            color: 'grey',
        },
        dateCreated: {
            position: 'absolute',
            fontSize: 11,
            bottom: 30,
            left: 0,
            right: 50,
            textAlign: 'right',
            color: 'grey',
        },
        logo: {
            width: 50,
            height: 50,
            marginBottom: 20,
            alignSelf: 'center',
            resizeMode: 'contain'
        },
    });

    const reportTypes = Array.from(new Set(props.reports.map(report => report.type)));

    const generateCoverPage = (type) => (
        <Page style={[styles.body, { backgroundColor: '#f0f0f0', justifyContent: 'center', alignItems: 'center' }]}>
            <Text style={[styles.title, { textAlign: 'center' }]}>{type} Reports</Text>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />
            <Text style={styles.dateCreated} render={() => (
                `${dateCreated}`
            )} fixed />
        </Page>
    );

    return (
        <Document>

            {/* Cover Page */}
            <Page style={styles.body}>
                <Image style={styles.logo} src={Logo} alt="QualityWorx" title="QualityWorx"/>

                <Text style={styles.title}>Audit Report</Text>
                <Text style={styles.date}>Date: {dateCreated}</Text>
                <Text style={styles.header}>A detailed overview of the Audit Reports</Text>
                <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed/>
                <Text style={styles.dateCreated} render={() => (
                    `${dateCreated}`
                )} fixed/>
            </Page>

            {/* Table of Contents */}
            <Page style={styles.body}>
            <Text style={styles.tableOfContentsTitle}>Table of Contents</Text>
                {reportTypes.map((type, index) => (
                    <Text style={styles.tocItem} key={index}>
                        {index + 1}. {type} Reports
                    </Text>
                ))}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
                <Text style={styles.dateCreated} render={() => (
                    `${dateCreated}`
                )} fixed />
            </Page>
            
            {reportTypes.map((type, typeIndex) => (
                <React.Fragment key={typeIndex}>
                    {generateCoverPage(type)}
                    {props.reports.filter(report => report.type === type).map((report, reportIndex) => (
                        <Page style={styles.body} key={reportIndex}>
                            <View>
                                {report.type === 'Audit' ? <PdfDocument report={report} isDocument={false} /> : null}
                                {report.type === 'Sharps' ? (() => {
                                    const filteredSharps = props.zoneSharps.sharps.filter(x => x.zone.id === report.zone.id);
                                    const reportSharps = report.sharps.map(sh => sh.sharpId);
                                    const finalFilteredSharps = filteredSharps.filter(x => reportSharps.includes(x.id));

                                    console.log(finalFilteredSharps);

                                    const newSharpsModel = { sharps: finalFilteredSharps };
                                    
                                    return <SharpPdfDocument report={report} zoneSharps={newSharpsModel} isDocument={false} />;
                                })() : null}
                            </View>
                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                                `${pageNumber} / ${totalPages}`
                            )} fixed />
                            <Text style={styles.dateCreated} render={() => (
                                `${dateCreated}`
                            )} fixed />
                        </Page>
                    ))}
                </React.Fragment>
            ))}
        </Document>
    );
};
